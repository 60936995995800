<!--suppress ALL -->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import EventBus from '@/event-bus'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinHotjar from '@/mixins/hotjar'
import MixinPartnero from '@/mixins/partnero'
import MixinRewardful from '@/mixins/rewardful'

import { allowedLocales, defaultLocale } from '@/options/localeOptions'
import { mapGetters } from 'vuex'
import { parseLanguageFromStartLocation } from '@/helpers/queryLanguageHelper'

export default {
  name: 'App',

  mixins: [MixinApiCalculations, MixinHotjar, MixinRewardful, MixinPartnero],

  computed: {
    ...mapGetters('user', ['isLoggedIn', 'isVerified', 'isLoggedIn']),

    apiBuild () {
      return this.$store.getters['app/getApiBuild']
    },
    isClientUpdated () {
      return this.$store.getters['app/isClientUpdated']
    }
  },

  watch: {
    apiBuild (oldValue, newValue) {
      if (oldValue !== null && newValue !== null && this.isClientUpdated) {
        this.newVersionNotify()
      }
    },

    isVerified: {
      handler (value) {
        if (!this.isLoggedIn) return

        if (value === false) {
          this.$router.push({ name: 'verification', query: this.$router.currentRoute.query })
        }
      }
    },

    isLoggedIn: {
      handler (value) {
        if (value) {
          this.setupHotjar()
        }
      },
      immediate: true
    }
  },

  created () {
    const currentLang = parseLanguageFromStartLocation(this.$router.history._startLocation) || this.$route.query.lang || localStorage.getItem('lang')

    if (!currentLang || currentLang === 'undefined' || !allowedLocales.includes(currentLang)) {
      localStorage.setItem('lang', defaultLocale)
      // this.$vuetify.lang = defaultLocale
      this.$i18n.locale = defaultLocale
      // this.$moment.locale(defaultLocale)
      document.documentElement.lang = defaultLocale
    } else {
      localStorage.setItem('lang', currentLang)
      // this.$vuetify.lang = currentLang
      this.$i18n.locale = currentLang
      // this.$moment.locale(currentLang)
      document.documentElement.lang = currentLang
    }

    // Usetiful
    if (process.env.VUE_APP_USETIFUL) {
      this.insertUsetiful()
    }

    // Rewardful affiliate program
    if (process.env.VUE_APP_REWARDFUL_ID) {
      this.insertRewardfulCode()
      this.insertRewardful()
    }

    // Partner affiliate program
    if (process.env.VUE_APP_PARTNERO_PROGRAM_ID) {
      this.setupPartnero()
    }
  },

  mounted () {
    EventBus.$on('idea-settings-update', () => {
      this.getAllCalculations()
    })

    this.$store.commit('app/setClientBuild',
      Math.floor(document.documentElement.dataset.buildTimestampUtc / 1000))
  },

  methods: {
    newVersionNotify () {
      this.$notify({
        title: this.$t('updateBox.title'),
        dangerouslyUseHTMLString: true,
        message: `${this.$t('updateBox.message')} <a class="el-button float-right mt-3 decoration-none" onclick="window.location.reload(true);">${this.$t('reload')}</a>`,
        duration: 0,
        type: 'info'
      })
    },

    insertUsetiful () {
      const a = document.getElementsByTagName('head')[0]
      const r = document.createElement('script')
      r.async = 1
      r.src = 'https://www.usetiful.com/dist/usetiful.js'
      r.setAttribute('id', 'usetifulScript')
      r.dataset.token = process.env.VUE_APP_USETIFUL
      a.appendChild(r)
    }
  }
}
</script>

<style lang="scss">
#app {
  height: 100%;
}
</style>
