<template>
  <span
    class="pill"
    :class="{'text-uppercase': textUppercase}"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: 'IbPill',

  props: {
    textUppercase: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.pill {
  font-family: outfit-regular;
  background-color: $color-blue;
  color: $color-white;
  line-height: 1.2;
  margin: 0 auto;
  font-size: 11px;
  padding: 3px 5px 2px;
  border-radius: 6px;
  display: inline-block;
}
</style>
