<template>
  <button
    v-on-clickaway="onClickAway"
    class="ib-button justify-content-center"
    type="button"
    :class="classes"
    :disabled="disabled"
    :style="{'min-width': minWidth}"
    @click="onClick"
  >
    <span class="d-flex justify-content-center align-items-center">
      <!-- Loading -->
      <i
        v-if="loading"
        class="el-icon-loading"
        :class="{'mr-2': hasDefaultSlot}"
      />
      <!-- Loading -->

      <!-- Icon left slot -->
      <span v-if="$slots['icon-left']" class="ib-button-icon d-inline-flex mr-2">
        <slot name="icon-left" />
      </span>
      <!-- Icon left slot -->

      <!-- Icon -->
      <template v-if="!$slots['icon'] && icon && !iconRight">
        <i
          v-if="!loading"
          class="ib-button-icon"
          :class="[icon, iconClasses]"
        />
      </template>

      <!-- /Icon -->
      <slot name="icon" />
      <slot />

      <!-- Icon right -->
      <template v-if="!$slots['icon'] && icon && iconRight">
        <i
          v-if="!loading"
          class="ib-button-icon"
          :class="[icon, iconClasses]"
        />
      </template>
      <!-- /Icon right -->

      <!-- Icon right slot -->
      <span v-if="$slots['icon-right']" class="ib-button-icon d-inline-flex ml-2">
        <slot name="icon-right" />
      </span>
      <!-- /Icon right slot -->
    </span>
  </button>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'

/** Sizes **/
const SIZES = ['sm', 'md', 'lg']

/** Colors **/
const COLORS = ['blue', 'white', 'yellow', 'black', 'red']

/** Font Families **/
const FONT_FAMILIES = ['regular', 'medium', 'semi-bold', 'bold']

export default {
  name: 'IbButton',

  directives: {
    onClickaway
  },

  props: {
    size: {
      type: String,
      validator: value => {
        return SIZES.includes(value)
      },
      default: 'md'
    },

    variant: {
      type: String,
      validator: value => {
        return COLORS.includes(value)
      },
      default: 'blue'
    },

    icon: {
      type: String,
      default: null
    },

    iconRotate: {
      type: Boolean,
      default: false
    },

    fontFamily: {
      type: String,
      validator: value => {
        return FONT_FAMILIES.includes(value)
      },
      default: 'bold'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    },

    outline: {
      type: Boolean,
      default: false
    },

    iconRight: {
      type: Boolean,
      default: false
    },

    submitButton: {
      type: Boolean,
      default: false
    },

    transition: {
      type: Boolean,
      default: true
    },

    minWidth: {
      type: String,
      default: ''
    },

    textUppercase: {
      type: Boolean,
      default: false
    },

    forceIconRotated: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isIconRotated: false
    }
  },

  computed: {
    classes () {
      return [
        this.colorClass,
        this.sizeClass,
        this.disabledClasses,
        this.outlineClass,
        this.fontFamilyClass,
        this.submitButtonClass,
        this.transitionClass,
        { 'ib-button-text-uppercase': this.textUppercase }
      ]
    },

    iconClasses () {
      return {
        'ml-2': this.hasDefaultSlot && this.iconRight,
        'mr-2': this.hasDefaultSlot && !this.iconRight,
        'font-weight-bold': !this.outline,
        'icon-rotate-right': this.iconRotate && this.isIconRotated && this.iconRight,
        'icon-rotate-left': this.iconRotate && this.isIconRotated && !this.iconRight
      }
    },

    colorClass () {
      return this.outline ? `ib-button-${this.variant}-outline` : `ib-button-${this.variant}`
    },

    sizeClass () {
      return this.hasDefaultSlot
        ? `ib-button-${this.size}`
        : this.outline ? `ib-button-${this.size}-icon-outline` : `ib-button-${this.size}-icon`
    },

    outlineClass () {
      return this.outline ? `ib-button-outline ib-button-${this.size}-outline` : null
    },

    fontFamilyClass () {
      return `ib-button-${this.fontFamily}`
    },

    isDisabled () {
      return this.disabled || this.loading
    },

    disabledClasses () {
      return {
        'ib-button-disabled': this.isDisabled,
        'ib-button-outline-disabled': this.isDisabled
      }
    },

    submitButtonClass () {
      return this.submitButton ? `ib-button-${this.size}-submit-button` : ''
    },

    hasDefaultSlot () {
      return !!this.$slots.default
    },

    transitionClass () {
      return this.transition ? 'transition-class' : ''
    }
  },

  mounted () {
    if (this.forceIconRotated) {
      this.isIconRotated = true
    }
  },

  methods: {
    onClick (event) {
      if (this.iconRotate) {
        this.isIconRotated = !this.isIconRotated
      }
      this.$emit('click', event)
    },

    onClickAway () {
      if (this.iconRotate && this.isIconRotated) {
        this.resetIconRotation()
      }
    },

    resetIconRotation () {
      this.isIconRotated = false
    }
  }
}
</script>

<style scoped lang="scss">
.ib-button {
  color: $color-white;
  border-width: 0;
  cursor: pointer;
  border-radius: 8px;
}

.transition-class {
  transition: all ease-in-out .25s;
}

.ib-button .ib-button-icon {
  transition: transform .3s;
}

.icon-rotate-right {
  transform: rotate(-180deg);
  transition: transform .3s;
}

.icon-rotate-left {
  transform: rotate(180deg);
  transition: transform .3s;
}

/* Font families */
.ib-button-regular {
  font-family: outfit-light;
}

.ib-button-medium {
  font-family: outfit-regular;
}

.ib-button-semi-bold {
  font-family: outfit-medium;
}

.ib-button-bold {
  font-family: outfit-semi-bold;
}
/* /Font families */

/* Colors */
.ib-button-blue {
  background-color: $color-primary;
  border: 1px solid $color-primary;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

.ib-button-white {
  color: #393e47;
  background-color: $color-white;
  border: 1px solid transparent;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

.ib-button-yellow {
  background-color: $color-yellow;
  border: 1px solid $color-yellow;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

.ib-button-black {
  background-color: #000000;
  border: 1px solid #000000;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

.ib-button-red {
  background-color: $color-red;
  border: 1px solid $color-red;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

/* /Colors */

/* Hover states */

.ib-button-blue:hover {
  box-shadow: 0 16px 15px -12px rgba(0, 96, 238, 0.45);
  background-color: $color-primary;
  color: $color-white;
}

.ib-button-white:hover {
  box-shadow: 0 16px 15px -12px rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  border-color: transparent;
  color: #393e47;
}

.ib-button-yellow:hover {
  background-color: $color-yellow;
  box-shadow: 0 16px 15px -12px rgba(255, 186, 0, 0.45);
}

.ib-button-black:hover {
  box-shadow: 0 16px 15px -12px rgba(10, 10, 10, 0.45);
}

.ib-button-red:hover {
  box-shadow: 0 16px 15px -12px rgba(229, 57, 53, 0.45);
  background-color: $color-red;
  color: $color-white;
}

/* /Hover states */

/* Active states */
.ib-button:active {
  box-shadow: none;
  outline: none;
  opacity: 0.7;
}
/* /Active states */

/* Outline states */
.ib-button-outline {
  background-color: $color-white;
  border-width: 2px;
  border-style: solid;
}

.ib-button-blue-outline {
  text-align: center;
  border-color: $color-primary;
  color: $color-primary;
}

.ib-button-yellow-outline {
  border-color: $color-yellow;
  color: $color-yellow;
}

.ib-button-black-outline {
  border-color: #000000;
  color: #000000;
}

.ib-button-red-outline {
  border-color: $color-red;
  color: $color-red;
}

.ib-button-blue-outline:hover {
  background-color: $color-primary;
  color: $color-white;
}

.ib-button-yellow-outline:hover {
  background-color: $color-yellow;
  color: $color-white;
}

.ib-button-black-outline:hover {
  background-color: #000000;
  color: $color-white;
}

.ib-button-red-outline:hover {
  background-color: $color-red;
  color: $color-white;
}

/* /Outline states */

/* Disabled states */
.ib-button-disabled {
  pointer-events: none;
}

.ib-button-outline-disabled {
  opacity: 0.5;
}
/* /Disabled states */

/* Sizes */
.ib-button-sm {
  font-size: 14px;
  padding: 8px 12px;
}

.ib-button-md {
  font-size: 17px;
  padding: 10px 24px;
}

.ib-button-lg {
  font-size: 19px;
  padding: 18px 20px;
}

.ib-button-sm-outline {
  padding: 7px 12px;
}

.ib-button-md-outline {
  padding: 9px 24px;
}

.ib-button-lg-outline {
  padding: 17px 20px;
}

.ib-button-sm-icon {
  font-size: 14px;
  padding: 10px 12px;
}

.ib-button-sm-icon-outline {
  font-size: 14px;
  padding: 8px 12px;
}

.ib-button-md-icon-outline {
  font-size: 17px;
  padding: 13px 12px;
}

.ib-button-md-icon {
  font-size: 17px;
  padding: 15px 14px;
}

.ib-button-lg-icon {
  font-size: 19px;
  padding: 22px 20px;
}

.ib-button-lg-icon-outline {
  font-size: 19px;
  padding: 20px 18px;
}

.ib-button-sm .ib-button-icon {
  font-size: 19px;
}

.ib-button-md .ib-button-icon {
  font-size: 21px;
}

.ib-button-lg .ib-button-icon {
  font-size: 24px;
}

/* /Sizes */

.ib-button-sm-submit-button {
  letter-spacing: 1px;
  padding: 6px 20px;
}

.ib-button-md-submit-button {
  letter-spacing: 1px;
  padding: 8px 35px;
  font-size: 16px;
}

.ib-button-text-uppercase {
  text-transform: uppercase;
}
</style>
