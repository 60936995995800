<template>
  <button
    class="ib-button-tab"
    :class="classes"
    :style="{ height: height }"
    @click="onClick"
  >
    <!-- Icon -->
    <template v-if="!$slots['icon'] && icon">
      <i
        class="ib-button-icon mr-2"
        :class="[icon]"
      />
    </template>
    <!-- /Icon -->
    <slot />
  </button>
</template>

<script>
/* Colors */
const COLORS = ['violet', 'violet1', 'gray']

export default {
  name: 'IbButtonTab',

  props: {
    isActive: {
      type: Boolean,
      default: false
    },

    height: {
      type: String,
      default: '36px'
    },

    color: {
      type: String,
      validator: value => {
        return COLORS.includes(value)
      },
      default: 'violet'
    },

    icon: {
      type: String,
      default: null
    },

    animation: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return [
        this.colorClass,
        { active: this.isActive, 'ai-buddy-animation-pulse-button': this.animation }
      ]
    },

    colorClass () {
      return `ib-button-tab-${this.color}`
    }
  },

  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">

button {
  border: 2px solid #F4F5F6;
  background: #F4F5F6;
  border-radius: 6px;
  font-size: 16px;
  padding: 0 25px;
  transition: all 0.25s;
  width: 100%;

  @include media-breakpoint-up($md) {
    width: 140px;
  }
}

.ib-button-tab {
  color: #081E4A;

  &.ib-button-tab-gray {
    &:not(.active) {
      &:hover {
        background: $color-white;
        border: 2px solid $color-white;
      }
    }

    &.active {
      border: 2px solid $color-eggshell-white;
      background: $color-eggshell-white;
    }
  }

  &.ib-button-tab-violet {
    &:not(.active) {
      &:hover {
        background: rgb(133, 9, 246, 0.1);
        border: 2px solid rgb(133, 9, 246, 0.001);
        color: #8509F6 !important;
        opacity: 1;
      }
    }

    &.active {
      background: $color-ai-light;
      border: 2px solid $color-ai;
      color: $color-ai;
    }
  }

  &.ib-button-tab-violet1 {
    &:not(.active) {
      background: rgb(133, 9, 246, 0.1);
      border: 2px solid rgb(133, 9, 246, 0.001);
      color: #8509F6 !important;

      &:hover {
        border: 2px solid $color-ai;
        opacity: .75;
      }
    }

    &.active {
      background: $color-ai-light;
      border: 2px solid $color-ai;
      color: $color-ai;
      //opacity: .75;
    }
  }
}

</style>
